import React from 'react'
import { Spin } from 'antd'

import GridContainer from '../components/GridContainer'

const LoadingPage = () => {
  return (
    <>
      <GridContainer>
        <div style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100%',
        }}>
          <Spin size="large" />
        </div>
      </GridContainer>

    </>
  )
}

export default LoadingPage