import React, { lazy, Suspense } from 'react'
import { Layout } from 'antd'
import {
  Switch, Route, Redirect, useHistory,
} from 'react-router-dom'

import '../i18n.config'

import LoadingPage from '../pages/LoadingPage'
const SearchPage = lazy(() => import('../pages/Search'))
const SoDetail = lazy(() => import('../pages/SoDetail'))
const Appoint = lazy(() => import('../pages/appointment'))
const Verify = lazy(() => import('../pages/appointment/Verify'))
// const UnderConstruction = lazy(() => import('../pages/UnderConstruction'))

const { Content } = Layout

const PageLayout = () => {

  const history = useHistory()
  // useEffect(() => {
  if (window.location.hash.startsWith('#/')) {
    history.push(window.location.hash.replace('#', '')) // or history.replace
  }
  // }, [])

  return (
    <Layout className="layout">
      <Layout className="site-layout">
        <Content>
          <div className="site-layout-background">
            <Suspense fallback={<LoadingPage />}>
              <Switch>
                <Route exact={true} path="/" component={SearchPage} />
                <Route exact={true} path="/ref/:soNo" component={SoDetail} />
                <Route exact={true} path="/appoint/:track" component={Appoint} />
                <Route exact={true} path="/appoint/:track/verify" component={Verify} />
                <Route exact={true} path="/appoint/:track/:step" component={Appoint} />
                <Route exact={true} path="/loading" component={LoadingPage} />
                <Redirect from="*" to="/" />
              </Switch>
            </Suspense>
          </div>
        </Content>
        {/* <Footer style={{ textAlign: 'center' }}>Tracking © Index Livingmall 2021 Version 0.1</Footer> */}
      </Layout>
    </Layout>
  );
}

export default PageLayout
