import { Row, Col } from 'antd'

import './GridContainer.css'

const GridContainer = (props: any) => {
  const { children } = props

  return (
    <div className="grid-container">
      <Row className="grid-row">
        <Col xs={{ span: 24 }} sm={{ span: 16, offset: 4 }} md={{ span: 12, offset: 6 }} >
          {children}
        </Col>
      </Row>
    </div>
  )
}

export default GridContainer