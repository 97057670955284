
import React, { useEffect } from 'react'
import { useRecoilState, RecoilRoot } from 'recoil'
import { ConfigProvider } from 'antd'
import i18next from "i18next"

import enUS from 'antd/lib/locale/en_US'
import thTH from 'antd/lib/locale/th_TH'

import moment from 'moment'
import 'moment/locale/th'

import PageLayout from './layouts/Layout'

import { BrowserRouter as Router } from 'react-router-dom'

import './css/App.css'
import { langState } from './store/atom'

const App: React.FC = () => {

  //const [lang] = useRecoilState(langState)

  // useEffect(() => {
  //   i18next.changeLanguage(lang)
  //   if (lang === 'en') {
  //     moment.locale('en')
  //   } else {
  //     moment.locale('th')
  //   }
  // }, [lang])
  // locale={lang === 'en' ? enUS : thTH}
  return (
    <RecoilRoot>
      <ConfigProvider >
        <Router>
          <PageLayout />
        </Router>
      </ConfigProvider>
    </RecoilRoot>

  );
}

export default App;
