import i18n from "i18next"
import { initReactI18next } from "react-i18next"

import en from "./locales/en.json";
import th from "./locales/th.json";

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: en
    },
    th: {
      translation: th
    }
  },
  lng: "en",
  fallbackLng: "en",
  keySeparator: false,

  interpolation: {
    escapeValue: false,
    /**
     * Add interpolation format method to customize the formatting
     */
    format: (value:any, format:any, lng:any) => {
      if (format === "uppercase") {
        return value.toUpperCase();
      }

      return value;
    }
  }
});
